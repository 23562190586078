import React, { useEffect, useRef, useState } from 'react'
import './HeaderMenu.css'

function HeaderMenu() {
  const [currentSection, setCurrentSection] = useState('hero');
  const observerRef = useRef(null);
  const [MenuDisplay, setMenuDisplay] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '-10% 0px -90% 0px',
      threshold: 0
    };

    const callback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    };

    observerRef.current = new IntersectionObserver(callback, observerOptions);
    
    // Start observing sections
    document.querySelectorAll('section').forEach(section => {
      observerRef.current.observe(section);
    });

    // Clean up
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;

      // Calculate distance from the bottom of the screen
      const distanceToBottom = documentHeight - (scrollPosition + windowHeight);

      // Check if we're at the bottom of the screen
      if (distanceToBottom < 50) {
        setCurrentSection('experience')
      }
      if (distanceToBottom < 20) {
        setCurrentSection('contact')
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up - remove scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this effect runs only once after component mounting

  return (
    <header id='header'>
        <div className='container'>
          <i className='fa-solid fa-bars fa-2x' onClick={() => setMenuDisplay(!MenuDisplay)}></i>
        {/* <img src="/Images/geometry.png" alt="portfolio logo" /> */}
        {(MenuDisplay || screenWidth > 900) && (
          <nav>
              <ul>
                  <li><a className={`hover-underline-animation ${currentSection === 'hero' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#hero'>Home</a></li>
                  <li><a className={`hover-underline-animation ${currentSection === 'about' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#about'>About</a></li>
                  <li><a className={`hover-underline-animation ${currentSection === 'skills' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#skills'>Skills</a></li>
                  <li><a className={`hover-underline-animation ${currentSection === 'projects' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#projects'>Projects</a></li>
                  <li><a className={`hover-underline-animation ${currentSection === 'education' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#education'>Education</a></li>
                  <li><a className={`hover-underline-animation ${currentSection === 'experience' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#experience'>Experience</a></li>
                  <li><a className={`hover-underline-animation ${currentSection === 'contact' ? 'active' : ''}`} onClick={() => setMenuDisplay(false)} href='#contact'>Contact</a></li>
              </ul>
          </nav>
        )}
        </div>
        {/* <div id='debug-line-top'></div>
        <div id='debug-line-bottom'></div> */}
    </header>
  )
}

export default HeaderMenu
