import React from 'react'
import './projects.css';
import Project from './Project';

function ActivityHub() {

  return (
    <Project data={{
      heading: 'Activity Hub',
      skills: ['PHP', 'MySQL', 'HTML', 'CSS'],
      problem: 'Following the success of the Middleton Music Academy (MMA) database, it became apparent that other schools could benefit from similar administration software. However, the challenge lay in the fact that many features of the MMA database were tailored specifically to Middleton\'s requirements, potentially limiting its applicability to other schools\' workflows.',
      solution: 'Drawing from the insights gained during the development of the previous application, I embarked on creating a more adaptable and versatile solution suitable for multiple schools. This enhanced software offers several benefits, including flexibility for tutors to seamlessly navigate between multiple schools with a single sign-in. Additionally, expanded feature sets, such as integrated attendance records, contribute to its day-to-day utility. Restructuring the backend architecture prioritized authentication and security protocols, ensuring the safeguarding of student data. The result is a customizable application that harmonizes with existing school systems.',
      learnings: 'The endeavor to create a more flexible application necessitated careful consideration of component design to ensure reusability without compromising flexibility. Recognizing the prevalent use of mobile devices among tutors, I prioritized responsiveness in the new design to enhance accessibility. This project underscored the importance of adaptability and user-centric design in catering to diverse school environments.',
      link: 'https://demo.activityhub.co.nz/demo',
      link_description: 'Activity Hub Demo Site',
      images: [
        { src: '/Images/Projects/ActivityHub/Dashboard.png', alt: ''},
        { src: '/Images/Projects/ActivityHub/Instruments.png', alt: ''},
        { src: '/Images/Projects/ActivityHub/Lessons.png', alt: ''},
        { src: '/Images/Projects/ActivityHub/LessonDetails.png', alt: ''},
        { src: '/Images/Projects/ActivityHub/LessonSignup.png', alt: ''},
        { src: '/Images/Projects/ActivityHub/Lessons_Mobile.png', alt: ''},
    ]
    }} />
  )
}

export default ActivityHub