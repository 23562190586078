import React, { useState } from 'react';
import './Projects.css'
import { projectsList } from './projectsList';
import SkillsComponent from './SkillsComponent';

function Projects() {
  const [currentProject, setcurrentProject] = useState(null);
  const [currentImage, setcurrentImage] = useState(null);

  function projectDetails(project){
    if(currentProject !== project) {
      setcurrentProject(project)
      setcurrentImage(project.images[0])
    }
    else setcurrentProject(null)
  }

  return (
    <section id='projects'>
      <div className="container">
        <h2 className='menu-tag'>Projects</h2>
        <div className="projects-container">
          {projectsList.map((project, index) => (
            <div
              className={`project`}
              key={index}
            >
              <div className={`details aws-container`}>
                <h3 id={project.tag}>{project.title}
                </h3>
                <h4>{project.sub}</h4>
                <p className='detail-content'>{project.content}</p>
                {project.subProjects && project.subProjects.length === 0 && (
                  <p className='link hover-underline-animation' onClick={() => projectDetails(project)}>{currentProject && currentProject.title === project.title ? 'Show less...' : 'Learn more...'}</p>
                )}
                <div className='skill-box'>
                  {project.skills.map((skill, index) => (
                    <SkillsComponent skill={skill} key={index} />
                  ))}
                </div>

                <div className='subProjects'>
                  {project.subProjects.map((subProject, index) => (
                    <div className={`subProject`} key={index}>
                      <h3 className={`${subProject.class}`} id={subProject.tag}>{subProject.title}</h3>
                      <h4>{subProject.sub}</h4>
                      <p>{subProject.content}</p>
                      <div className={`skill-box`}>
                        {subProject.skills.map((skill, index) => (
                          <SkillsComponent skill={skill} key={index} />
                        ))}
                      </div>
                      <p className='link hover-underline-animation' onClick={() => projectDetails(subProject)}>{currentProject && currentProject.title === subProject.title ? 'Show less...' : 'Learn more...'}</p>
                    </div>
                  ))}
                </div>

              </div>
              <div className='image'>
                  <img onClick={() => projectDetails(project)} src={`/Images/Projects/${project.images[0]}`} alt="" />
              </div>
            </div>
          ))}
        </div>
        {currentProject && (
          <div className="modal">
            <header className='mb-2'>
              <div className='headings'>
                <h3>{currentProject.title}</h3>
                <h4>{currentProject.sub}</h4>
              </div>
              <i className='fa-solid fa-xmark fa-2x' onClick={() => setcurrentProject(null)}></i>
            </header>
            <div className="content">
              <div className="details">
                <div className='skill-box'>
                  {currentProject.skills.map((skill, index) => (
                    <SkillsComponent skill={skill} key={index} />
                  ))}
                </div>
                <div className='detail-long'>
                  <pre>{currentProject.longContent}</pre>
                </div>
                  {(currentProject.attachments.length > 0 || currentProject.git || currentProject.link) && (
                    <div className="link-section">
                      <hr />
                      <h4><i className="fa-solid fa-arrow-up-right-from-square pe-2"></i>Links:</h4>
                      {currentProject.attachments.map((attachment, index) => (
                        <div className='website' key={index}>
                          <i className="fa-solid fa-paperclip me-2"></i>
                          <a href={`/Images/Projects/${attachment.link}`} target='_blank' rel="noreferrer" className='hover-underline-animation external-link'>{attachment.description}</a>
                        </div>
                      ))}
                      {currentProject.git && (
                        <div className='github'>
                          <i className="fa-brands fa-github me-2"></i>
                          <a href={currentProject.git} target='_blank' rel="noreferrer" className='hover-underline-animation external-link'>GitHub Repository</a>
                        </div>
                      )}
                      {currentProject.link && (
                        <div className='website'>
                          <i className="fa-solid fa-globe me-2"></i>
                          <a href={currentProject.link} target='_blank' rel="noreferrer" className='hover-underline-animation external-link'>{currentProject.linkDescription ? currentProject.linkDescription : 'View Website'}</a>
                        </div>
                      )}
                    </div>
                  )}
              </div>
              <div className='images'>
                  <img src={`/Images/Projects/${currentImage}`} alt="" />
                  { currentProject.images && currentProject.images.length > 1 && (
                    <div className='image-thumbnails'>
                      {currentProject.images.map((image, index) => (
                        <img src={`/Images/Projects/${image}`} alt="" onClick={() => setcurrentImage(image)}
                        className={currentImage === image ? 'active' : ''} key={index}/>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Projects;
