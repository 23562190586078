import React from 'react'
import './About.css';


function About() {
  return (
    <section id='about'>
      <div className='container'>
        <h2 className='menu-tag'>About Me</h2>
        <p>Can I tell you a secret...? I haven't always been a web developer. For almost a decade I worked as a secondary school Music and Mathematics teacher before realising my passion for web development. Teaching has sharpened my communication skills and taught me how to break down complex concepts. Now, I'm able to apply that same clarity to software development. I'm always striving to create user-friendly experiences and solving problems efficiently.</p>

        <p>Currently, I am working on projects such as Activity Hub (a school extra-curricular management system for students and tutors alike) and working with small businesses to help them carve their own space on the web. I'm always looking for the next challenge and am energized by finding technical solutions to everyday hassles.</p>

        <p>I'm also on the lookout for some more permanent work, so <a href='mailto: hello@lukenoble.nz' className='hover-underline-animation special-green'>hit me up</a> if you are interested in what I could bring to your organisation.</p>
        {/* <a className='link' style={{marginLeft: '10px'}} href="/Resume Luke Noble.pdf" download>Download my resume<i style={{color: 'white'}} className="fa-solid fa-file-arrow-down ms-2"></i></a> */}
      </div>
    </section>
  )
}

export default About