import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <section id='contact'>
      <div className="container">
        <h2>Contact</h2>
        <p>Let's Connect!</p>

        <div className='linkGroup'>
          <a className='link hover-underline-animation' href="mailto: hello@lukenoble.nz">
            <i className="fa-solid fa-envelope icon"></i>Send me an email
          </a>
          <a className='link hover-underline-animation' href="https://linkedin.com/in/lukenoble" target='_blank' rel='noopener noreferrer'>
            <i className="fa-brands fa-linkedin icon"></i>Connect on LinkedIn
          </a>
        </div>
      </div>
    </section>
  )
}

export default Contact