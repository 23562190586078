import React from 'react'
import './projects.css';
import Project from './Project';

function SouthernAudio() {

  return (
    <Project data={{
      heading: 'Southern Audio Website',
      skills: ['Wordpress', 'PHP', 'HTML', 'CSS'],
      problem: '',
      solution: '',
      learnings: '',
      link: '',
      link_description: '',
      images: [
        { src: '/Images/Projects/SouthernAudio/Home.png', alt: ''},
        { src: '/Images/Projects/SouthernAudio/Hire.png', alt: ''},
        { src: '/Images/Projects/SouthernAudio/Details.png', alt: ''}
    ]
    }} />
  )
}

export default SouthernAudio