import React from 'react'

function Education() {
    const qualifications = [
        {title: 'Graduate Diploma in Information Technology', sub: 'Software Development', institute: 'Open Polytechnic | Te Pūkenga', dates: 'November, 2023', gpa: '(GPA: 9.0)'},
        {title: 'Graduate Diploma in Teaching', sub: 'Secondary Education', institute: 'New Zealand Graduate School of Education', dates: 'May, 2015', gpa: ''},
        {title: 'Bachelor of Music', sub: 'Digital Music, Sonic Art and Recording Technology', institute: 'University of Canterbury', dates: 'April, 2014', gpa: ''}
    ]
  return (
    <section id='education'>
      <div className="container">
        <h2>Education</h2>
        <div className="projects-container">
          {qualifications.map((qualification, index) => (
            <div className='qualification' key={index}>
              <h3>{qualification.title}</h3>
              <h4>{qualification.sub} {qualification.gpa}</h4>
              <p className='my-0'>{qualification.institute}</p>
              <p>{qualification.dates}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Education