export const projectsList = [
    { 
        title: 'Activity Hub',
        sub: 'School Extra-Curricular Database Management', 
        tag: 'project-activity-hub', 
        images: ['ActivityHub/Dashboard_2.webp', 'ActivityHub/Events.webp', 'ActivityHub/Lessons.webp', 'ActivityHub/LessonSignUp.webp', 'ActivityHub/LessonDetails.webp', 'ActivityHub/Attendance.webp'], 
        attachments: [],
        content: 'A comprehensive web application that enables schools to gain greater control of their extra-curricular activities. Activity Hub has been designed so music department teachers and tutors can have real-time access to lesson data, event management tasks and instrument hire accessible from anywhere; removing paper record headaches.', 
        longContent: 'The idea for Activity Hub came out of my own awareness of the challenges that schools face in keeping track of what is happening in a busy music department and my desire to put into practice the skills I had been learning over the course of my Graduate Diploma. \n\nA first iteration of this application was put into use during the 2023 school year by a large christchurch school who found it to be helpful in keeping track of the lessons and events happening weekly in the department. This proof-of-concept helped inform me as to how to create an even more useful version of the application that could be used for not only recording when events are scheduled to occur, but to also assist with managing legal requirements such as creating attendance records. \n\nThis is an ongoing project for me. It has been a fantastic experience for learning about Vue.js and Laravel as well as how to work with API requests, authentication and data security. This application is in use still by a department of about 15 staff. In the future I would love to see this application evolve to also assist with extra-curricular sport management as this is another massive area for school administration.',
        link: 'https://demo.activityhub.co.nz/demo', 
        linkDescription: 'Activity Hub Demo', 
        git: 'https://github.com/LukeOP/activity-hub',
        skills: [
            { title: 'Vue.js', icon: '../Skills/vue.png'}, 
            { title: 'Laravel', icon: '../Skills/laravel.png'}, 
            { title: 'MySQL', icon: '../Skills/MySQL.png'},
            { title: 'JavaScript', icon: '../Skills/javaScript.png'}, 
            { title: 'PHP', icon: '../Skills/PHP.png'}, 
            { title: 'HTML & CSS', icon: '../Skills/HTML_CSS.png'}, 
            { title: 'SCSS', icon: '../Skills/scss.png'}, 
        ],
        subProjects: []
    }, 
    // { 
    //     title: 'Middleton Music Academy', 
    //     sub: 'School Extra-Curricular Database Management', 
    //     images: ['MMA/Events.png'], 
    //     attachments: [],
    //     content: 'Contract with Middleton Grange School to create a music database application that can enable tutors and admin staff to view and update student lesson information, event data, and instrument hire contracts. I undertook all development requirements and met regularly with stakeholders to discuss functionality and future feature requests. This was a great opportunity to have my software products being used \'in the real world\'.', 
    //     link: '/middleton-music-academy', 
    //     linkDescription: '', 
    //     skills: ['VueJS', 'JavaScript', 'HTML & SCSS', 'PHP', 'MySQL'],
        // subProjects: []
    // }, 
    { 
        title: 'AWS Projects',
        sub: '', 
        tag: 'project-aws',
        images: [], 
        attachments: [],
        content: 'In my desire to upskill in cloud-based solutions I have taken on a few projects using AWS. Take a look at some examples of what I have learnt so far.', 
        link: '', 
        linkDescription: '', 
        git: '',
        skills: [],
        subProjects: [
            {
                title: 'Website Hosting',
                sub: 'Hosting Static Websites using S3 and Route 53',  
                tag: 'project-aws-website-hosting',
                images: ['AWS/P1/coverpage.png'], 
                attachments: [{link: 'AWS/P1/How I Hosted a Static Website on Amazon S3.pdf', description: 'How I hosted a static website with Amazon S3 and Route53'}],
                // attachments: 'AWS/P1/How I Hosted a Static Website on Amazon S3.pdf',
                // attachmentDescription: 'How I hosted a static website with Amazon S3 and Route53',
                content: '', 
                longContent: 'In my desire to upskill in cloud-based solutions I have taken on a few projects using AWS.\n\nDuring this project I learnt how to create a new S3 bucket, upload a static website and set public permissions so it was viewable. I also learnt about creating preasigned URLs to share an object for a limited amount of time. In addition to working with S3 I also had some exposure to Route53 for domain registration.\n\nTo check out all my progress and see examples of my work, take a look at the provided link below.',
                link: '', 
                linkDescription: '', 
                git: 'https://github.com/LukeOP/AWS-NextWork-Project1',
                skills: [
                    {
                        title: 'Amazon S3',
                        icon: 'AWS/S3.svg'
                    },
                    {
                        title: 'Route 53',
                        icon: 'AWS/route53.jpeg'
                    } 
                ],
                class: 'aws'
            },
            {
                title: 'Visualising Netflix Data',
                sub: 'Using Amazon QuickSight to Visualise Data',  
                tag: 'project-aws-quicksight',
                images: ['AWS/P2/coverpage.png'], 
                attachments: [{link: 'AWS/P2/How I used Amazon QuickSight to visualise data.pdf', description: 'How I used Amazon QuickSight to visualise data'}],
                // attachments: 'AWS/P2/How I used Amazon QuickSight to visualise data.pdf',
                // attachmentDescription: 'How I used Amazon QuickSight to visualise data',
                content: '',  
                longContent: 'In my desire to upskill in cloud-based solutions I have taken on a few projects using AWS.\n\nIn this project I used Amazon QuickSight to create a dashboard of key metrics from a Netflix dataset. This required me to upload my data files to an S3 bucket and then connect my QuickSight account to this dataset. Once I had my data I could have a bit of fun and experiment with filters and graph types to create interesting and engaging graphical representations of the data.\n\nTo check out all my progress and see examples of my work, take a look at the provided link below.',
                link: '', 
                linkDescription: '', 
                skills: [
                    {
                        title: 'Amazon S3',
                        icon: 'AWS/S3.svg'
                    },
                    {
                        title: 'Amazon QuickSight',
                        icon: 'AWS/quicksight.png'
                    } 
                ],
                class: 'aws'
            },
            {
                title: 'AWS Chatbot',
                sub: 'Utilizing Amazon Lex and Lambda',  
                tag: 'project-aws-chatbot',
                images: ['AWS/P3/coverpage.png'], 
                attachments: [{link: 'AWS/P3/How I made a chatbot with Amazon Lex.pdf', description: 'How I built a chatbot with Amazon Lex'}],
                // attachments: 'AWS/P3/How I made a chatbot with Amazon Lex.pdf',
                // attachmentDescription: 'How I built a chatbot with Amazon Lex',
                content: '', 
                longContent: 'In my desire to upskill in cloud-based solutions I have taken on a few projects using AWS.\n\nIn this project I got some hands-on experience using Amazon Lex to create a chatbot for use in a fictional banking app. The chatbot was designed to be able to display user funds in multiple accounts and to transfer funds. I used many of the features available in Amazon Lex incuding intents, utterances, fallbacks, slots, and context tags. I connected my chatbot to a Lambda Python script which handled the \'backend\' processing of the request, by making the transfers and getting account balances.\n\nWhile only a simple implementation of chatbots I have learnt a lot about how they are connected and can see the benefit of using it in future projects.\n\nTo check out all my progress and see examples of my work, take a look at the provided link below.',
                link: '', 
                linkDescription: '', 
                skills: [
                    {
                        title: 'Amazon Lex',
                        icon: 'AWS/lex.png'
                    },
                    {
                        title: 'Amazon Lambda',
                        icon: 'AWS/lambda.png'
                    } 
                ],
                class: 'aws'
            },
            {
                title: 'Setting Access Permissions with IAM',
                sub: 'Introduction to AWS IAM',  
                tag: 'project-aws-iam',
                images: ['AWS/P4/coverpage.png'], 
                attachments: [{link: 'AWS/P4/Managing Permissions with AWS IAM.pdf', description: 'Managing Permissions with AWS IAM'}],
                // attachments: 'AWS/P4/Managing Permissions with AWS IAM.pdf',
                // attachmentDescription: 'Managing Permissions with AWS IAM',
                content: '', 
                longContent: 'In my desire to upskill in cloud-based solutions I have taken on a few projects using AWS.\n\nIn this project I created two EC3 instances to simulate a development and a production environment. My goal was to set a permissions policy and apply it to a user group that would restrict actions that could be taken on a production environment EC2 instance. To achieve this I created a permissions policy using JSON and created a new IAM user that was assigned to a user group. By applying my policy to this group I was able to restict access to the production envirnment while retaining permissions on the development instance.\n\nTo check out all my progress and see examples of my work, take a look at the provided link below.',
                link: '', 
                linkDescription: '', 
                skills: [
                    {
                        title: 'AWS IAM',
                        icon: 'AWS/IAM.png'
                    },
                    {
                        title: 'Amazon EC2',
                        icon: 'AWS/EC2.png'
                    } 
                ],
                class: 'aws'
            },
            {
                title: 'Managing A CI/CD Pipeline',
                sub: 'A Six Part Series Using The AWS Code Family',  
                tag: 'project-aws-cicd-pipeline',
                images: ['AWS/P5/coverpage.png'], 
                attachments: [
                    {link: 'AWS/P5/Setting Up a Web App and IDE in the Cloud.pdf', description: 'Part 1 - Setting Up a Web App and IDE with Cloud9'},
                    {link: 'AWS/P5/Setting Up A Git Repository with AWS CodeCommit.pdf', description: 'Part 2 - Setting Up A Git Repository with AWS CodeCommit'},
                    {link: 'AWS/P5/Secure Project Dependencies with AWS CodeArtifact.pdf', description: 'Part 3 - Secure Project Dependencies with AWS CodeArtifact'},
                    {link: 'AWS/P5/Package an App with CodeBuild.pdf', description: 'Part 4 - Package an App with CodeBuild'},
                ],
                // attachments: 'AWS/P5/Setting Up a Web App and IDE in the Cloud.pdf',
                // attachmentDescription: 'Part 1 - Setting Up a Web App and IDE with Cloud9',
                content: '', 
                longContent: 'In my desire to upskill in cloud-based solutions I have taken on a few projects using AWS. This project is broken up into multiple parts:\n\nTo check out all my progress and see examples of my work, take a look at the provided links below.\n\nPart 1:\nI setup the Cloud9 IDE and installed Maven and Java. I then used Maven to generate a new Java web app from a template.\n\nPart 2:\nI created a git repository using AWS CodeCommit and made changes to local files which were then staged, committed, and then pushed to the remote origin.\n\nPart 3:\nIn this part I setup AWS CodeArtifact to manage dependency packages for my web application.\n\nPart 4:\nI utilized CodeBuild to manage the automated building of my project and upload it to an S3 bucket instance.',
                link: '', 
                linkDescription: '', 
                skills: [
                    {
                        title: 'Amazon EC2',
                        icon: 'AWS/EC2.png'
                    },
                    {
                        title: 'Git',
                        icon: '../Skills/Git.png'
                    },
                    {
                        title: 'Cloud9',
                        icon: 'AWS/cloud9.png'
                    },
                    {
                        title: 'AWS CodeCommit',
                        icon: 'AWS/codeCommit.png'
                    },
                    {
                        title: 'CodeArtifact',
                        icon: 'AWS/codeArtifact.png'
                    } ,
                    {
                        title: 'CodeBuild',
                        icon: 'AWS/codeBuild.png'
                    } 
                ],
                class: 'aws'
            },
        ]
    }, 
    { 
        title: 'NobleNotes', 
        sub: 'Student Learning Platform for Music Tuition',  
        tag: 'project-noble-notes',
        images: ['NobleNotes/Lesson.png', 'NobleNotes/Dashboard.png', 'NobleNotes/Unit_Select.png', 'NobleNotes/Video.png', 'NobleNotes/Guitar_Tab.png'], 
        attachments: [],
        content: 'Online learning platform for year 9 and 10 music students to have self-directed and paced lessons from remote learning environments during the Covid-19 lockdowns. This was really well received by students and was a fun and valuable opportunity for me to try my hand at this kind of work. This was when I discovered my passion for web development.', 
        longContent: 'NobleNotes was born out of an element of necessity. Like much of the world, students and teachers were thrown into a world of online life during the 2020 pandemic and it is fair to say it left schools scrambling. I developed NobleNotes as a way to deliver online learning materials to my students where they could continue to learn in a more self-paced environment. \n\nThis application included notes, imbedded audio, video and learning quizes to support my students. Feedback from using this application was very positive and I continued to use and develop it over the few years of uncertainty. \n\nOnce school life on-site had resumed, NobleNotes continued to be a valuable asset for large, split level classes to assist with their differenciated learning. This was the first instance of trying to use web development to solve an issue I was facing and it sparked my curiosity about how I could continue to use technology to assist in education.',
        link: '', 
        linkDescription: '', 
        git: '',
        skills: [
            { title: 'PHP', icon: '../Skills/PHP.png'}, 
            { title: 'HTML & CSS', icon: '../Skills/HTML_CSS.png'}, 
        ],
        subProjects: []
    }, 
    { 
        title: 'Flutter World Time Application', 
        sub: 'Hands-On Experience With Flutter and Dart',  
        tag: 'project-worldTimes',
        images: ['WorldTime/ide.png', 'WorldTime/mobile.png'], 
        attachments: [],
        content: 'Mobile application that fetches the local time of a selected city. This application, while functionally quite simple, was an excericise to get some hands-on experience using Flutter and Dart. I wanted to challenge myself to try something new and in particular, mobile development.', 
        longContent: 'I took on this project to learn about mobile development and the way Flutter uses widgets to create user interfaces that can be displayed in multiple platforms. Through this project I used native widgets and created some of my own.\n\nI look forward to using what I have learnt during this trial to develop some of my own ideas in Flutter.',
        link: '', 
        linkDescription: '', 
        git: 'https://github.com/LukeOP/Flutter_World_Time_Application',
        skills: [
            { title: 'Flutter', icon: '../Skills/flutter.png'}, 
            { title: 'Dart', icon: '../Skills/dart.png'}, 
        ],
        subProjects: []
    },
    { 
        title: 'Southern Audio', 
        sub: 'Local Business Website',  
        tag: 'project-southern-audio',
        images: ['SouthernAudio/EquipmentDetails.png', 'SouthernAudio/Equipment.png', 'SouthernAudio/Landing.png'], 
        attachments: [],
        content: 'Development of a Wordpress site for a Christchurch-based sound equipment hire company. Cart/quote functionality was required for sending enquiries to the administrator.', 
        longContent: 'Southern Audio is a Christchurch-based audio hire company. Back in 2015, I was asked whether I could assist them with their online marketing by creating a website with a cart/quote feature. This was my first step into the world of web development, having not spent any time studying or working with WordPress or any web languages before this was a huge undertaking for me.\n\nI was able to develop a website that meet the requirements and learnt about some of the basics of HTML, CSS and PHP. I look back at this project as a humble beginning for me, but a reminder of how far I have come and how much there still is to learn.',
        link: '', 
        linkDescription: '', 
        git: '',
        skills: [
            { title: 'WordPress', icon: '../Skills/wordpress.png'}, 
            { title: 'PHP', icon: '../Skills/PHP.png'}, 
            { title: 'HTML & CSS', icon: '../Skills/HTML_CSS.png'}, 
        ],
        subProjects: []
    }
  ]