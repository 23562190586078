import React from 'react'

function SkillsComponent({skill}) {
  return (
    <div className="skill">
        {skill.icon && (
            <img className='skill-icon' src={`/Images/Projects/${skill.icon}`} alt="" />
        )}
      <p className='skill-text' >{skill.title}</p>
    </div>
  )
}

export default SkillsComponent
