export const experiences = [
    {
        position: 'Founder | Web Developer', 
        employer: 'Activity Hub', 
        dates: 'July 2023 - Present', 
        location: 'Christchurch, NZ', 
        description: 'Visionary and developer for an interactive web application that can streamline extra-curricular administration for schools.'
    },
    {
        position: 'Secondary School Teacher', 
        employer: 'Middleton Grange School', 
        dates: '2015 - Present', 
        location: 'Christchurch, NZ', 
        description: 'Teacher of Music and Mathematics for students aged 11 - 18 years. Teacher In Charge of Performance Music with responsibility for performance music groups, leaders, and events.'
    },
    {
        position: 'School Board Member', 
        employer: 'Middleton Grange School', 
        dates: 'Sept 2022 - Feb 2024', 
        location: 'Christchurch, NZ', 
        description: 'Elected Staff Representive. Involvement in strategic planning and governance oversight.'
    },
    {
        position: 'Founder | Audio Technician', 
        employer: 'Southern Audio Limited', 
        dates: 'Feb 2013 - Present', 
        location: 'Christchurch, NZ', 
        description: 'Operation and administration for sound equipment hire in the greater Christchuch region. Involved meeting with clients to ascertain their requirements and make equipment recomendations based to best suit.'
    },
    {
        position: 'Secondary School Teacher', 
        employer: 'Cashmere High School', 
        dates: 'Jan - Dec 2017', 
        location: 'Christchurch, NZ', 
        description: 'Teacher of Mathematics for students aged 13 - 18 years.'
    }
  ]