import React, { useEffect, useState } from 'react'
import './Experience.css'
import { experiences } from './ExperienceList'

function Experience() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section id='experience'>
      <div className="container">
        <h2>Work Experience</h2>
        {experiences.map((experience, index) => {
          return (
            <div className='experience' key={index}>
              {screenWidth > 575 && (
                <div className="dates">{experience.dates}</div>
              )}
              <div className="details">
                <h3>{experience.position}</h3>
                <h4>{experience.employer}</h4> 
              {screenWidth < 575 && (
                <div className="dates-inline">({experience.dates})</div>
              )}
                <div>{experience.description}</div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Experience