import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = ({images}) => {

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings}>
        { images.map((image, index) => {
            return (
                <div key={index} style={{width: '70%'}}>
                    <img style={{maxWidth: '100%'}} src={image.src} alt={image.alt} />
                </div>   
            )
        })}
    </Slider>
  );
};

export default ImageCarousel;
