import React, { useState } from 'react';
import SkillTile from './SkillTile';
import './Skills.css';
import { skillArray } from './skillList';

function Skills() {
  const [currentSkill, setCurrentSkill] = useState(null);

  return (
    <section id='skills'>
      <div className="container">
        <h2 className='menu-tag'>Skills</h2>
        <div className="skills-container">
          {skillArray.map((skill, index) => (
            <SkillTile
              skill={skill}
              currentSkill={currentSkill}
              key={index}
              onClick={() => setCurrentSkill(currentSkill && skill.name === currentSkill.name ? null : skill)}
            />
          ))}
        </div>
        <div className={`skill-overview ${currentSkill ? 'open' : ''}`}>
          {currentSkill && (
            <>
              <div className='skill-header'>
                <div className='skill-header-name'>
                  <img src={`/Images/Skills/${currentSkill.image}`} alt={currentSkill.name} />
                  <span>{currentSkill.fullName ? currentSkill.fullName : currentSkill.name}</span>
                </div>
                <i className='fa fa-xmark' onClick={() => setCurrentSkill(null)}></i>
              </div>
              {currentSkill.description && (
                <div className="skill-description">{currentSkill.description}</div>
              )}
              <ul className='skill-list'>
                {currentSkill.list.map((skill, index) => (
                  <li className='skill-item' key={index}>{skill}</li>
                ))}
              </ul>
              {currentSkill.projects && (
                <>
                <h3>Projects that use {currentSkill.name}:</h3>
                <div className="skill-projects"> 
                  {currentSkill.projects.map((project, index) => (
                    <a href={`#${project.tag}`} className='skill-project' key={index}>{project.name}</a>
                  ))}
                </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Skills;
