import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const delay = 500; // Specify the delay in milliseconds (e.g., 500 milliseconds)
    const timer = setTimeout(() => {
        window.scrollTo(0, 0); // Scroll to top after the delay
    }, delay);

    return () => clearTimeout(timer); // Clean up the timer to prevent memory leaks
    }, [pathname]);

  return null;
}