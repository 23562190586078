import React from 'react'
import './projects.css';
import Project from './Project';

function MMA() {

  return (
    <Project data={{
      heading: 'Middleton Music Academy',
      skills: ['Vue.JS', 'Laravel', 'PHP', 'MySQL', 'HTML', 'SCSS'],
      problem: 'Middleton Grange School\'s extra-curricular music department faced numerous administrative challenges: communication hurdles, paper-based instrument records prone to misplacement, and difficulties in updating student lesson schedules. These obstacles impeded efficient management and necessitated a streamlined solution to allow staff to prioritise educational outcomes over administrative burdens.',
      solution: 'In 2022, I proposed and developed a digital solution to address these challenges effectively. By leveraging an online database, our solution empowered Music Academy staff with convenient access to relevant information from any device, facilitating easy updates and ensuring lesson data accuracy. Additionally, students and parents could seamlessly apply for lessons online, with their data automatically prepared for tutor assignment. Digital recording of instrument stock and streamlined event management further enhanced operational efficiency. Staff feedback has been overwhelmingly positive, with notable improvements in event management efficiency and continued utilization of the system by Middleton Grange.',
      learnings: 'This project marked a significant milestone in my journey as a web developer, providing invaluable insights into technical skills and stakeholder engagement. I gained proficiency in Vue.js and Laravel, exploring their integration for the first time. Learning to create and consume APIs, implement state management techniques, handle authentication, and design robust database structures were all integral to the project\'s success. Moreover, the project underscored the importance of user-centric design and the unpredictable nature of end-user interactions, highlighting the critical role of thorough data validation processes.',
      // link: 'https://activityhub.co.nz',
      // link_description: 'View Demo Site',
      images: [
        { src: '/Images/Projects/MMA/Dashboard.png', alt: ''},
        { src: '/Images/Projects/MMA/Events.png', alt: ''},
        { src: '/Images/Projects/MMA/EventDetails.png', alt: ''},
        { src: '/Images/Projects/MMA/Instruments.png', alt: ''}
    ]
    }} />
  )
}

export default MMA