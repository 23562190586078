import React from 'react'
import './projects.css';
import ImageCarousel from '../ImageCarousel';
import { useNavigate } from 'react-router-dom';

function Project({data}) {
    const navigate = useNavigate()

    function handleClick(link) {
      navigate(link)
    }

  return (
    <div className='projectPage pb-5'>
        <span className='back' onClick={() => handleClick('/')}>
        <i className="fa-solid fa-arrow-left fa-2x"></i></span>
        <div className='container'>
            <h1>Project: {data.heading}</h1>
            <hr />
            <div className='skillBox'>
              {data.skills.map((skill, index) => (
                <p className='skill' key={index}>{skill}</p>
              ))}
            </div>
            <h2>The Problem:</h2>
            <p>{data.problem}</p>
            <h2>The Solution:</h2>
            <p>{data.solution}</p>
            <h2>Key Learnings:</h2>
            <p>{data.learnings}</p>
            <hr />
            { data.link &&(
                <a href={data.link} target='_blank' rel='noopener noreferrer' className="link">{data.link_description}</a>
            )}
            <h3>Application Screenshots:</h3>
            <ImageCarousel images={data.images}/>
        </div>
    </div>
  )
}

export default Project