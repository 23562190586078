export const skillArray = [
    {
        name: "Vue.js",
        image: 'Vue.png',
        description: 'Experience with modern JS frameworks for component-based SPAs, utilising dynamic routing, handling of props, custom events and lifecycle hooks. Use with RESTful API integration in larger projects.',
        list: ['State Management (Pinia)', 'Data Binding', 'Dynamic Routing', 'Lifecycle Hooks'],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}]},
    { 
        name: "Laravel", 
        image: 'Laravel.png', 
        description: 'Competent user of Laravel as a backend. I can confidently build API endpoints, controllers, models, factories, migrations and more to produce a clean, functioning backend. Experience using Pest for PHP testing.',
        list: ['MVC', 'API Creation', 'Authentication', 'Form Validation', 'Eloquent Queries', 'Pest Testing', 'Artisan Commands'],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}] },
    { 
        name: "JavaScript", 
        image: 'JavaScript.png', 
        description: 'Experience using JavaScript within frameworks and by creating composables for utilities that can are imported into other components.',
        list: ['Asynchronous Code', 'NPM', 'Axios',],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}] },
    { 
        name: "HTML & CSS", 
        image: 'HTML_CSS.png', 
        description: 'Experience with HTML form creation and validation, accessibility considerations, and responsive design. I have experience with preprocessors like SCSS to assist in writing modular and scalable styles.',
        list: ['Semantic Elements', 'Responsive Design', 'Flexbox/Grid Layouts', 'Bootstrap', 'Media Queries', 'Animations and Transitions'],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}, {name: 'NobleNotes', tag: 'project-noble-notes'}, {name: 'Southern Audio', tag: 'project-southern-audio'}] },
    { 
        name: "MySQL", 
        image: 'MySQL.png', 
        description: 'Able user of SQL queries. I can write SQL queries that utilise joins, nested queries and data manipulation. My experience has been designing database schema for small to medium applications. ',
        list: ['Joins', 'Subqueries', 'phpMyAdmin', 'Constraints'],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}, {name: 'NobleNotes', tag: 'project-noble-notes'}] },
    { 
        name: "PHP", 
        image: 'PHP.png', 
        description: 'Working understanding of PHP syntax through applications utilizing Laravel, Wordpress, or static websites. Familiar with using PHP as a back-end language for handling databaes integration and error handling.',
        list: ['OOP', 'Laravel', 'MySQL Integration', 'Error Handling', 'Pest Testing', 'CronJobs', 'Form Handling'],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}, {name: 'NobleNotes', tag: 'project-noble-notes'}, {name: 'Southern Audio', tag: 'project-southern-audio'}] },
    { 
        name: "Git", 
        fullName: "Git Version Control", 
        image: 'Git.png',
        description: 'Experience creating and cloning Git repositories. Experienced with staging, committing and branching mostly through VSCode, but with some experience using CLI. Familiar with GitHub and deploying using GitHub Actions.',
        list: ['Branching', 'Push/Pull', 'Stashing/Committing', 'GitHub', 'GitHub Actions'],
        projects: [{name: 'Activity Hub', tag: 'project-activity-hub'}, {name: 'NobleNotes', tag: 'project-noble-notes'}, {name: 'AWS Managing A CI/CD Pipeline', tag: 'project-cicd-pipeline'}, {name: '+ many uni projects', tag:
            'skills'
        }] },
    { 
        name: "React", 
        image: 'React.png',
        description: 'I have some experience creating React applications as another modern and popular JavaScript Library. I am familiar with creating and managing components, using and passing props, utilising common hooks,',
        list: ['Components', 'React Router', 'Props', 'useState/useEffect'],
        projects: [{name: 'This Portfolio!', tag: 'skills'}] },
    { 
        name: "AWS", 
        fullName: "Amazon Web Services", 
        image: 'AWS.png', 
        description: 'Developing knowledge of Amazon Web Services through personal projects that expose me to the different services available. I have had some hands-on projects of which my documentation is listed below in the Projects section.',
        list: ['S3', 'Lambda', 'Route53', 'Amazon Lex', 'QuickSight'],
        projects: [{name: 'Website Hosting', tag: 'project-aws-website-hosting'}, {name: 'Visualising Netflix Data', tag: 'project-aws'}, {name: 'AWS Chatbot', tag: 'project-aws-chatbot'}, {name: 'Setting Access Permissions with IAM', tag: 'project-aws-iam'}, {name: 'AWS Managing A CI/CD Pipeline', tag: 'project-aws-cicd-pipeline'}] 
    },
    { 
        name: "Flutter", 
        fullName: "Flutter and Dart", 
        image: 'flutter.png', 
        description: 'Beginner understanding of Flutter framework and it\'s use of widgets for UI development. Basic experience in developing mobile applications.',
        list: ['Widgets', 'Mobile Development', 'Dart'],
        projects: [{name: 'World Time Application', tag: 'project-worldTimes'}] 
    },
  ];