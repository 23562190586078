import React from 'react'
import './SkillTile.css'

function SkillTile({skill, currentSkill, onClick}) {
  return (
    <div className={`skill-container 
    ${currentSkill !== null && currentSkill.name === skill.name ? 'active' : ''} 
    ${currentSkill !== null && currentSkill.name !== skill.name ? 'inactive' : ''}`} 
    onClick={onClick}>
      <div className='skill-image'>
          <img src={`/Images/Skills/${skill.image}`} alt="" />
      </div>
      <div className='skill-name'>{skill.name}</div>
    </div>
  )
}

export default SkillTile