import React from 'react'
import './Hero.css'

export default function Hero() {
  return (
    <section id='hero'>
        <div className="container">
            <div className="details">
                <div>Hi I'm</div>
                <h1 id='name'><span className='first-name'>Luke </span>Noble</h1>
                <h2>Software Developer</h2>
            </div>
            <div className="image">
                <p>I'm looking to partner with innovative people who want to make <span className='special-green'>epic software.</span></p>
                <p>Got a project in mind? Let's connect!</p>
                <div className='CTA'>
                    {/* <button className='btn btn-primary'>Let's Connect!</button> */}
                </div>
                {/* <img className='hero-img' src="/Images/hero-img2.png" alt="" /> */}
            </div>
            <div className="socials">
                <a className='link' href="https://linkedin.com/in/lukenoble" target='_blank' rel='noopener noreferrer'>
                    <img src="/Images/Socials/linkedin.png" alt="linkedin icon" aria-label='button to linkedin profile' />
                </a>
                <a className='link' href="https://github.com/LukeOP" target='_blank' rel='noopener noreferrer'>
                    <img src="/Images/Socials/github.png" alt="github icon" aria-label='button to github profile' />
                </a>
                <a className='link' href="mailto: hello@lukenoble.nz" target='_blank' rel='noopener noreferrer'>
                    <img src="/Images/Socials/email.png" alt="email icon" aria-label='button to send an email' />
                </a>
            </div>
        </div>
      
    </section>
  )
}
