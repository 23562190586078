// import './SideElements.css';
import './Home.css';
import About from './About';
import Skills from './Skills';
import Projects from './Projects';
import Experience from './Experience';
import Contact from './Contact';
import Education from './Education';
import HeaderMenu from './HeaderMenu';
import Hero from './Hero';

function Home() {

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <main>
        <HeaderMenu />
        <Hero />
        {/* <Title scrollToSection={scrollToSection} /> */}
        <About id='about' scrollToSection={scrollToSection} />
        <Skills id='skills' />
        <Projects id='projects' />
        <Education id='education' />
        <Experience id='experience' />
        <Contact id='contact' />
    </main>
  );
}

export default Home;

