import React from 'react'
import './projects.css';
import Project from './Project';

function NobleNotes() {

  return (
    <Project data={{
      heading: 'noblenotes.co.nz',
      skills: ['PHP', 'HTML', 'CSS'],
      problem: 'In 2020, amidst the global pandemic, as a secondary school music teacher, I faced the challenge of ensuring continuity in my students\' education despite the closure of physical classrooms. Uncertain about the duration of the lockdown, it was imperative to provide a means for students to access course materials and continue learning remotely.',
      solution: 'Recognizing that many of my students had access to devices, thanks to our school\'s Bring Your Own Device (BYOD) policy, I saw an opportunity. Building on my experience of conducting online lessons via Teams, I created an online platform called noblenotes.co.nz. This platform allowed students to access course materials and resources at their own pace. The initiative was well-received by students, who appreciated the ability to refer back to notes even after we resumed in-person classes.',
      learnings: 'This project marked my initial foray into web development for educational purposes, despite my lack of prior experience with PHP, HTML, or CSS. Through self-guided learning via readings and tutorial videos, I acquired the necessary skills to develop a software solution tailored to the needs of my students. While hindsight reveals areas for improvement, this endeavor ignited my passion for both education and software technology, laying the foundation for further exploration and growth in these two areas of interest.',
      link: '',
      link_description: '',
      images: [
        { src: '/Images/Projects/NobleNotes/Dashboard.png', alt: 'NobleNotes.co.nz Dashboard'},
        { src: '/Images/Projects/NobleNotes/Lessons.png', alt: 'Lesson Layout'},
        { src: '/Images/Projects/NobleNotes/Audio.png', alt: 'Audio Examples'},
        { src: '/Images/Projects/NobleNotes/Video.png', alt: 'Video Examples'},
    ]
    }} />
  )
}

export default NobleNotes