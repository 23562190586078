import { Route, Routes, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './App.css';
import Home from './Components/Home';
import MMA from './Components/Projects/MMA';
import ActivityHub from './Components/Projects/ActivityHub';
import NobleNotes from './Components/Projects/NobleNotes';
import SouthernAudio from './Components/Projects/SouthernAudio';
import ScrollToTop from './scrollToTop';

function App() {
  const location = useLocation();

  return (
    <div className='App'>
      <TransitionGroup>
        <ScrollToTop />
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/middleton-music-academy" element={<MMA />} />
            <Route path="/activity-hub" element={<ActivityHub />} />
            <Route path="/noble-notes" element={<NobleNotes />} />
            <Route path="/southern-audio" element={<SouthernAudio />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
